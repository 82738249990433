import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { Dialog } from "vant";
export default {
  name: "OrderSearch",
  data: function data() {
    return {
      locationSearch: "",
      //路径？的内容
      source: "",
      //渠道
      active: 1,
      //导航索引
      userName: "",
      //姓名
      phone: "",
      //联系电话
      message: "" //留言

    };
  },
  //获取路由参数
  created: function created() {
    this.source = this.$route.params.source;
    this.locationSearch = location.search;
  },
  methods: {
    //提交订单
    submitCustomize: function submitCustomize() {
      var _this = this;

      var name = this.userName;
      var phone = this.phone;
      var request = this.message;
      var source = this.source;

      if (name == null || name == "") {
        Dialog.alert({
          message: "请填写姓名"
        });
        return;
      }

      if (phone == null || phone == "") {
        Dialog.alert({
          message: "请填写联系电话"
        });
        return;
      }

      if (request == null || request == "") {
        Dialog.alert({
          message: "请填写留言"
        });
        return;
      }

      this.sendCodeDisable = true;
      var url = "/haoma52-server/customize/submitCustomize";
      var params = {
        name: name,
        phone: phone,
        request: request,
        resource: source
      };
      this.$axios.post(url, params).then(function (resp) {
        if (resp.data.status == _this.SUCCESS) {
          Dialog.alert({
            message: "您的需求提交成功！"
          }); //清除表单信息

          _this.clearForm();
        } else {
          Dialog.alert({
            message: resp.data.message
          });
        }
      });
    },
    //清除表单信息
    clearForm: function clearForm() {
      this.userName = "";
      this.phone = "";
      this.message = "";
    }
  }
};
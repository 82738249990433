import "core-js/modules/es.object.to-string.js";
export default {
  name: "OrderResult",
  data: function data() {
    return {
      order: null,
      activeNames: [],
      leftTime: null,
      leftTimeStr: null,
      btnDisabled: false,
      resultBtnDisabled: false,
      show: false,
      payBtnText: '立即微信支付'
    };
  },
  methods: {
    orderStatus: function orderStatus() {
      switch (this.order.status) {
        case -1:
          return "待支付";

        case 0:
          return "审核中";

        case 3:
          return "下单成功";

        case 4:
          return "下单失败";

        case 5:
          return "已激活";

        case 10:
          return "已退单";
      }
    },
    goHome: function goHome() {
      this.$router.push('/');
    },
    // 开始支付倒计时显示
    showLeftTime: function showLeftTime() {
      var minutes = parseInt(this.leftTime / 1000 / 60);
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var seconds = parseInt(this.leftTime / 1000 % 60);
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.leftTimeStr = minutes + " : " + seconds;
      this.leftTime -= 1000;

      if (this.leftTime <= 0) {
        this.leftTimeStr = "支付超时";
      } else {
        setTimeout(this.showLeftTime, 1000);
      }
    },
    // 点击完成支付按钮 请求服务端查询是否已经完成支付
    complatePay: function complatePay() {
      this.resultBtnDisabled = true;
      var obj = this; // 3S后重新加载数据

      setTimeout(function () {
        obj.loadData(2);
      }, 5000);
    },
    // 点击 微信支付 按钮
    wechatPay: function wechatPay() {
      var _this = this;

      this.resultBtnDisabled = true;
      var orderId = this.$route.params.orderId;
      var redirectUrl = encodeURIComponent("https://m.52haoma.com/order-result/" + orderId); // JSAPI 跳转后返回 的code

      var code = this.$route.query.code;
      var url = '/ad-server/order/wechat-pay';
      var params = {
        id: this.order.id,
        code: code
      };
      this.$axios.post(url, params).then(function (resp) {
        var data = resp.data.data;

        if (data.h5Url) {
          // h5支付
          window.location.href = data.h5Url + "&redirect_url=" + redirectUrl;
        } else if (data.package) {
          // jsapi支付 微信浏览器中才有该对象
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke('getBrandWCPayRequest', {
            "appId": data.appId,
            //公众号ID，由商户传入
            "timeStamp": data.timeStamp,
            //时间戳，自1970年以来的秒数
            "nonceStr": data.nonceStr,
            //随机串
            "package": data.package_,
            "signType": data.signType,
            //微信签名方式：
            "paySign": data.paySign //微信签名

          }, function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              this.complatePay();
            }
          });
        }
      }).finally(function () {
        _this.resultBtnDisabled = false;
      });
    },
    // 加载数据
    loadData: function loadData(pay) {
      var _this2 = this;

      var orderId = this.$route.params.orderId;
      var url = '/ad-server/order/order-result?orderId=' + orderId;
      this.$axios.post(url).then(function (resp) {
        if (resp.data.data) {
          _this2.order = resp.data.data;
          var leftTime = _this2.order.leftTime;

          if (leftTime) {
            _this2.leftTime = leftTime;

            _this2.showLeftTime(); // 如果请求路径中包含参数pay=1 则立即调起支付


            if (pay == 1) {
              _this2.wechatPay();
            } else if (pay == 2) {
              _this2.payBtnText = '立即微信支付';
              _this2.btnDisabled = false;
              _this2.show = false;
            } else {
              // 支付调起后  回调页面打开
              _this2.payBtnText = '正在支付...';
              _this2.btnDisabled = true;
              _this2.show = true;
            }
          }
        }
      }).finally(function () {
        _this2.resultBtnDisabled = false;
      });
    }
  },
  mounted: function mounted() {
    this.loadData(this.$route.query.pay);
  }
};
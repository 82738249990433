import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import axios from 'axios'
import VueWechatTitle from 'vue-wechat-title'
// 2. 引入组件样式
import 'vant/lib/index.css';

import isIdcard from "./assets/js/idcard"
import {showDialog} from "vant";

const app = createApp(App)

app.use(router)
app.use(isIdcard)

app.use(VueWechatTitle)


app.mount('#app')
// 定义全局变量
app.config.globalProperties.$axios = axios;

axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if(response.data.status != "0000"){
        showDialog({message: response.data.message});
        return Promise.reject(response);
    }else{
        return response;
    }
}, function (error) {
    // this.$message.error("服务端开小差了，请稍后重试！！！");
    // 对响应错误做点什么
    return Promise.reject(error);
});
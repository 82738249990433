export const rulesList = {
    province_list: {
        110000: '5A',
        120000: '4A',
        130000: '3A',
        140000: 'AA',
        150000: 'AABB',
        160000: 'ABCDE',
        170000: 'EDCBA',
        180000: 'ABCD',
        190000: 'DCBA',
        200000: 'ABC',
        210000: 'CBA',
        220000: 'ABAB',
        230000: 'ABBA',
        240000: '4AB',
        250000: 'AAAB',
        260000: 'AABA',
        270000: '*3A*',
        280000: '*AABB*',
        290000: '*ABAB*',
        300000: '*ABCD*',
        310000: 'ABCDABCD',
        320000: 'ABCDEABCDE',
        330000: 'ABCABC',
        340000: '假山',
        350000: 'A*A*',
    },
    city_list: {
        110200: '10A',
        110300: '9A',
        110400: '8A',
        110500: '7A',
        110600: '6A',
        110700: '5A',
        120200: '4A_4A',
        120300: '3A_4A',
        120400: 'AABB_4A',
        120500: 'ABAB_4A',
        120600: 'ABBA_4A',
        120700: 'AA_4A',
        120800: 'ABCD_4A',
        120900: 'ABC_4A',
        121000: 'DCBA_4A',
        121100: 'CBA_4A',
        121200: '4A',
        130200: 'AAABAAA',
        130300: 'AABAAA',
        130400: 'ABAAA',
        130500: '5A_3A',
        130600: '4A_3A',
        130700: '4A_X3A',
        130800: 'AABB_X3A',
        130900: 'ABAB_X3A',
        131000: 'ABBA_X3A',
        131100: 'ABCD_X3A',
        131200: 'DCBA_X3A',
        131300: '3A_X3A',
        131400: '3AX_X3A',
        131500: '3A_3A',
        131600: 'AABB_3A',
        131700: 'ABAB_3A',
        131800: 'ABBA_3A',
        131900: 'AA_3A',
        132000: 'ABCDE_3A',
        132100: 'EDCBA_3A',
        132200: 'ABCD_3A',
        132300: 'DCBA_3A',
        132400: 'ABC_3A',
        132500: 'CBA_3A',
        132600: '*3A*_3A',
        132700: '3A',
        140200: '6A_AA',
        140300: '5A_AA',
        140400: '4A_AA',
        140500: '3A_AA',
        140600: 'ABCDEF_AA',
        140700: 'FEDCBA_AA',
        140800: 'ABCDE_AA',
        140900: 'EDCBA_AA',
        141000: 'ABCD_AA',
        141100: 'DCBA_AA',
        141200: 'AAAABAA',
        141300: 'AAABAA',
        141400: 'AABAA',
        141500: 'ABAA',
        141600: '4A_XXAA',
        141700: 'ABCD_XXAA',
        141800: 'DCBA_XXAA',
        141900: 'AABB_XXAA',
        142000: 'ABAB_XXAA',
        142100: 'ABBA_XXAA',
        142200: '3A_XXAA',
        142300: '*3A*_AA',
        142400: '*AABB*_AA',
        142500: '*ABAB*_AA',
        142600: '*ABBA*_AA',
        142700: 'AA',
        150200: 'AABBCCDDEE',
        150300: '4A_AABB',
        150400: '3A_AABB',
        150500: 'AABBCCDD',
        150600: 'AABBCC',
        150700: 'ABCD_AABB',
        150800: 'ABC_AABB',
        150900: 'DCBA_AABB',
        151000: 'CBA_AABB',
        151100: 'ABAB_AABB',
        151200: 'ABBA_AABB',
        151300: '*3A*_AABB',
        151400: 'AABB',
        160200: 'ABCDEFGH',
        160300: 'ABCDEFG',
        160400: 'ABCDEF',
        160500: '3A_ABCDE',
        160600: 'ABCDE',
        170200: 'HGFEDCBA',
        170300: 'GFEDCBA',
        170400: 'FEDCBA',
        170500: '3A_EDCBA',
        170600: 'EDCBA',
        180200: '4A_ABCD',
        180300: '3A_ABCD',
        180400: 'AABB_ABCD',
        180500: 'ABAB_ABCD',
        180600: 'ABBA_ABCD',
        180700: 'ABCD_ABCD',
        180800: 'ABCD',
        190200: '4A_DCBA',
        190300: '3A_DCBA',
        190400: 'AABB_DCBA',
        190500: 'ABAB_DCBA',
        190600: 'ABBA_DCBA',
        190700: 'ABCD_DCBA',
        190800: 'DCBA',
        200200: '5A_ABC',
        200300: '4A_ABC',
        200400: '3A_ABC',
        200500: '*3A*_ABC',
        200600: 'ABC',
        210200: '5A_DBC',
        210300: '4A_DBC',
        210400: '3A_DBC',
        210500: '*3A*_DBC',
        210600: 'CBA',
        220200: 'ABABABABAB',
        220300: 'ABABABAB',
        220400: '4A_ABAB',
        220500: '3A_ABAB',
        220600: 'AABB_ABAB',
        220700: 'ABAB_ABAB',
        220800: 'ABBA_ABAB',
        220900: 'ABCD_ABAB',
        221000: 'DCBA_ABAB',
        221100: 'ABABAB',
        221200: '*3A*_ABAB',
        221300: 'ABAB',
        230200: '4A_ABBA',
        230300: '3A_ABBA',
        230400: 'AABB_ABBA',
        230500: 'ABAB_ABBA',
        230600: 'ABBA_ABBA',
        230700: 'ABCD_ABBA',
        230800: 'DCBA_ABBA',
        230900: '*3A*_ABBA',
        231000: 'ABBA',
        240200: '9AB',
        240300: '8AB',
        240400: '7AB',
        240500: '6AB',
        240600: '5AB',
        240700: '*3A*_4AB',
        240800: '4AB',
        250200: '4A_AAAB',
        250300: '3A_AAAB',
        250400: 'AABB_AAAB',
        250500: 'ABAB_AAAB',
        250600: 'ABBA_AAAB',
        250700: 'ABCD_AAAB',
        250800: 'DCBA_AAAB',
        250900: '*3A*_AAAB',
        251000: 'AAAB_AAAB',
        251100: 'AAAB',
        260200: 'AAAAABA',
        260300: 'AAAABA',
        260400: 'AAABA',
        260500: 'AABA',
        270200: '*8A*',
        270300: '*7A*',
        270400: '*6A*',
        270500: '*5A*',
        270600: '*4A*',
        270700: '*3A3B*',
        270800: '*3A*',
        280200: '*AABBCCDD*',
        280300: '*AABBCC*',
        280400: '*AABB*',
        290200: '*ABABABAB*',
        290300: '*ABABAB*',
        290400: '*ABAB*',
        300200: '*ABCDEFG*',
        300300: '*ABCDEF*',
        300400: '*ABCDE*',
        300500: '*ABCD*',
        300600: '*GFEDCBA*',
        300700: '*FEDCBA*',
        300800: '*EDCBA*',
        300900: '*DCBA*',
        310200: 'ABCDABCD',
        320300: 'ABCDEABCDE',
        330400: 'ABCABC',
        340500: 'ABCDABCE',
        340600: 'ABCDABED',
        340700: 'ABCDAECD',
        340800: 'ABCDEBCD',
        350200: 'A*A*A*A*A*',
        350300: '*A*A*A*A*A',
        350400: 'A*A*A*A*',
        350500: 'A*A*A*',
        350600: 'A*A*',
    }
};

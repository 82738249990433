export default {
  name: "UnicomInNetProtocol",
  props: ['showUnicomProtocol', 'adOrder'],
  computed: {
    showFirst: {
      get: function get() {
        return this.showUnicomProtocol;
      },
      set: function set(newVal) {
        this.$emit('update:showUnicomProtocol', newVal);
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {},
  mounted: function mounted() {}
};
export default {
  name: "BroadInNetProtocol",
  props: ['showBroadProtocol'],
  computed: {
    showBroad: {
      get: function get() {
        return this.showBroadProtocol;
      },
      set: function set(newVal) {
        this.$emit('update:showBroadProtocol', newVal);
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {},
  mounted: function mounted() {}
};
export default {
  name: "MobileNetProtocol",
  props: ['showMobileProtocol', 'protocolContent'],
  computed: {
    showMobile: {
      get: function get() {
        return this.showMobileProtocol;
      },
      set: function set(newVal) {
        this.$emit('update:showMobileProtocol', newVal);
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {},
  mounted: function mounted() {}
};
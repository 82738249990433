import _slicedToArray from "E:/myprogram/52haoma_new/new-haoma52/haoma52-index/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _createForOfIteratorHelper from "E:/myprogram/52haoma_new/new-haoma52/haoma52-index/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.includes.js";
import { unicomAscription } from '@/assets/js/unicom_ascription';
import { telcomAscription } from '@/assets/js/telcom_ascription';
import { mobileAscription } from '@/assets/js/mobile_ascription';
import { broadAscription } from '@/assets/js/broad_ascription';
import { unicomAddress } from "@/assets/js/unicom_address";
import { telcomAddress } from "@/assets/js/telcom_address";
import { mobileAddress } from "@/assets/js/mobile_address";
import { broadAddress } from "@/assets/js/broad_address";
import { rulesList } from '@/assets/js/rulesList';
import { showToast } from 'vant';
import isIdcard from "@/assets/js/idcard";
import UnicomInNetProtocol from "@/components/UnicomInNetProtocol";
import TelcomNetProtocol from "@/components/TelcomNetProtocol";
import BroadNetProtocol from "@/components/BroadNetProtocol";
import MobileNetProtocol from "@/components/MobileNetProtocol";
export default {
  name: 'MainIndex',
  components: {
    UnicomInNetProtocol: UnicomInNetProtocol,
    TelcomNetProtocol: TelcomNetProtocol,
    BroadNetProtocol: BroadNetProtocol,
    MobileNetProtocol: MobileNetProtocol
  },
  data: function data() {
    return {
      // 联通协议
      showUnicomProtocol: false,
      // 电信协议
      showTelcomProtocol: false,
      // 广电协议
      showBroadProtocol: false,
      // 移动协议
      showMobileProtocol: false,
      // 预约弹窗
      show: false,
      // 选中号码
      phoneObj: {},
      showBsp: false,
      showCopyright: true,
      fullPath: '',
      customerServiceNumber: "",
      //客服热线电话
      source: "",
      //渠道
      queryObj: {
        platform: '52haoma'
      },
      //查询对象
      bottomSwitch: false,
      //标签栏默认底部开关
      optName: "运营商",
      //运营商
      optShow: false,
      //运营商弹出层开关
      ascriptionArea: "归属地",
      ascriptionAreaCode: [],
      ascriptionAreaShow: false,
      //归属地弹出层开关
      rule: "规律",
      //规律
      ruleShow: false,
      //规律弹出层开关
      rulesList: rulesList,
      price: "价格",
      //价格
      priceShow: false,
      //价格弹出层开关
      serverShow: false,
      //客服热线开关
      submitBtnFlag: false,
      //提交按钮开关
      //运营商选项数据
      optIds: [{
        text: '运营商',
        value: -1
      }, {
        text: '中国移动',
        value: 1
      }, {
        text: '中国联通',
        value: 2
      }, {
        text: '中国电信',
        value: 3
      }, {
        text: '中国广电',
        value: 5
      } // {text: '民营运营商', value: 4},
      ],
      //价格选项数据
      priceActions: [{
        name: '全部'
      }, {
        name: '0元专区',
        value: 0
      }, {
        name: '1元专区',
        value: 1
      }, {
        name: '2~100元',
        value: 2
      }, {
        name: '101~200元',
        value: 3
      }, {
        name: '201~300元',
        value: 4
      }, {
        name: '301~500元',
        value: 5
      }, {
        name: '501~1000元',
        value: 6
      }, {
        name: '1001~5000元',
        value: 7
      }, {
        name: '5001及以上',
        value: 8
      }],
      currentPage: 1,
      //当前页
      list: [],
      //列表数据
      emptyShow: false,
      //暂无数据提示开关
      orderObj: {
        userInfo: {}
      },
      areaStr: "",
      //地址
      addressShow: false,
      //省市区开关
      showAddress: false,
      //详细地址开关
      // 发送短信按钮
      sendCodeDisable: false,
      sendCodeText: '发送短信',
      seconds: 60,
      loading: false,
      finished: false,
      result: "",
      ascriptionList: unicomAscription,
      address: unicomAddress,
      addressOptions: unicomAddress,
      addressCascaderValue: null,
      showPriceDetail: false,
      // 分省协议
      protocol: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this2 = this;

    this.source = this.$route.params.source ? this.$route.params.source : '52haoma';
    this.loadData(-1);
    this.fullPath = window.location.href;
    window.addEventListener("scroll", function () {
      if (window.scrollY > 150) {
        _this2.showCopyright = false;
      } else {
        _this2.showCopyright = true;
      }
    });
  },
  methods: {
    // 移动 加载分省协议
    loadProtocol: function loadProtocol() {
      var _this3 = this;

      var provinceCode = this.queryObj.provinceCode;
      var protocol = this.protocol[provinceCode];

      if (!protocol) {
        var url = '/ad-server/mobile/query-protocol';
        this.$axios.post(url, {
          provinceCode: provinceCode
        }).then(function (resp) {
          _this3.protocol[provinceCode] = resp.data.data;
          _this3.showMobileProtocol = true;
        });
      } else {
        this.showMobileProtocol = true;
      }
    },
    openOrder: function openOrder(obj) {
      var _this4 = this;

      // 点击号码 打开预约窗口
      this.phoneObj = obj;
      this.show = true;
      this.orderObj.phoneNumber = obj.phoneNumber;
      this.orderObj.pOptId = obj.optId;
      this.orderObj.openSupplierId = obj.supplierId;
      this.orderObj.productId = obj.productId;
      this.orderObj.province = obj.province;
      this.orderObj.area = obj.area;
      this.orderObj.needPayPrice = obj.costPrice + obj.fee;
      this.orderObj.advanceFee = obj.fee;
      this.orderObj.lowConsume = obj.lowConsume;
      this.orderObj.onlineMonths = obj.onlineMonths;
      this.orderObj.firstMonthFeeType = obj.product.firstMonthFeeType;
      this.orderObj.configId = this.queryObj.configId;
      var deliverScope = obj.product.deliverScope;

      if (deliverScope == '省内') {
        // 省内发货的号码 下单地址限制在省内
        this.addressOptions = this.address.filter(function (item) {
          return _this4.orderObj.province.substring(0, 2) === item.text.substring(0, 2);
        });
      } else {
        this.addressOptions = this.address;
      }
    },
    // 确定选择运营商
    confirmOpt: function confirmOpt(obj) {
      var _this5 = this;

      var selectedOptions = obj.selectedOptions[0];
      this.optName = selectedOptions.text;
      this.queryObj.optId = selectedOptions.value;
      this.optShow = false;
      this.ascriptionList = unicomAscription;
      this.address = unicomAddress;

      if (this.queryObj.optId == 1) {
        this.ascriptionList = mobileAscription;
        this.address = mobileAddress;
      } else if (this.queryObj.optId == 3) {
        this.ascriptionList = telcomAscription;
        this.address = telcomAddress;
      } else if (this.queryObj.optId == 5) {
        this.ascriptionList = broadAscription;
        this.address = broadAddress;
      }

      this.addressOptions = this.address; // 查询条件中的省 和 城市

      var province = this.queryObj.province;
      var area = this.queryObj.area; // 修改 选中的号码归属地

      if (area) {
        this.ascriptionList.some(function (p) {
          if (province.substring(0, 2) == p.text.substring(0, 2)) {
            _this5.queryObj.province = p.text;
            _this5.queryObj.provinceCode = p.value;
            p.children.some(function (c) {
              if (area.substring(0, 2) == c.text.substring(0, 2)) {
                _this5.queryObj.area = c.text;
                _this5.queryObj.areaCode = c.value;
                _this5.ascriptionAreaCode = [_this5.queryObj.provinceCode, _this5.queryObj.areaCode];
                _this5.ascriptionArea = _this5.queryObj.area;

                _this5.loadData(1);

                return true;
              }
            });
            return true;
          }
        });
      }
    },
    // 确定选择的 归属地
    confirmAscription: function confirmAscription(obj) {
      var options = obj.selectedOptions;
      var provinceName = options[0].text;
      var cityName = options[1].text;
      this.ascriptionArea = cityName;

      if (cityName != '不限') {
        this.queryObj.province = provinceName;
        this.queryObj.area = cityName;
        this.queryObj.provinceCode = options[0].value;
        this.queryObj.areaCode = options[1].value;
      } else {
        this.queryObj.province = null;
        this.queryObj.area = null;
        this.queryObj.provinceCode = null;
        this.queryObj.areaCode = null;
      }

      this.ascriptionAreaShow = false;
      this.loadData(1);
    },
    // 跳转页面
    goPage: function goPage(flag) {
      switch (flag) {
        case 1:
          window.open("https://m.52haoma.com/ad-web/" + this.source, '_self');
          break;

        case 3:
          window.open("https://m.52haoma.com/ad-web/ad/specialorder/query", '_self');
          break;
      }
    },
    // 初始化 查询时候返回的归属地
    initAscriptionArea: function initAscriptionArea() {
      var _this6 = this;

      var province = this.queryObj.province;
      var cityName = this.queryObj.area;
      this.ascriptionList.some(function (p) {
        if (province.substring(0, 2) == p.text.substring(0, 2)) {
          p.children.some(function (c) {
            if (cityName.substring(0, 2) == c.text.substring(0, 2)) {
              _this6.ascriptionArea = cityName;
              _this6.ascriptionAreaCode = [p.value, c.value];
              return true;
            }
          });
          return true;
        }
      });
    },
    backtop: function backtop() {
      var timer = setInterval(function () {
        var osTop = document.documentElement.scrollTop || document.body.scrollTop;
        var ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;

        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    //根据条件查询数据，并覆盖原数据
    loadData: function loadData(pageNum) {
      var _this7 = this;

      // 如果上一次还没加载完成，停止下一次加载
      if (this.loading) {
        return;
      }

      if (this.queryObj.optId == 5 && this.queryObj.keyNum != null && this.queryObj.keyNum.length <= 2) {
        showToast('模糊查询请至少输入3位数字!');
        return;
      }

      this.loading = true;
      this.finished = false;
      this.result = "";

      if (pageNum) {
        this.currentPage = pageNum;
      }

      if (this.currentPage == 1) {
        this.list = [];
      }

      var url = '/ad-server/phoneNumber/query-new';
      this.queryObj.pageNum = this.currentPage;

      if (this.queryObj.optId == -1) {
        this.queryObj.optId = null;
      }

      this.$axios.post(url, this.queryObj).then(function (resp) {
        _this7.queryObj = resp.data.data;

        _this7.initAscriptionArea(); // 如果号码从接口查询 并且查询条件带规律 则弹出提醒


        if (_this7.queryObj.configId != null && (_this7.queryObj.pType || _this7.queryObj.aType) && _this7.currentPage == 1) {
          showToast('未查询到相关号码，系统为您推荐了其他优质靓号!');
        }

        var list = resp.data.list;

        if (list.length == 0) {
          if (_this7.currentPage == 1) {
            _this7.result = "没有符合您搜索条件的号码，换个条件试试";
          } else {
            _this7.result = "没有更多了，我是有底线的";
          }

          _this7.finished = true;
        } else {
          _this7.list = _this7.list.concat(list);

          if (list.length < 12) {
            _this7.result = "没有更多了，我是有底线的";
            _this7.finished = true;
          } else {
            _this7.currentPage++;
          }
        }

        _this7.loading = false;
      });
    },
    //规律选择
    rulesConfirm: function rulesConfirm(item) {
      this.finished = false;
      this.ruleShow = false;
      var p = item.selectedOptions[0].text;
      var a = item.selectedOptions[1].text;
      this.queryObj.pType = null;
      this.queryObj.aType = null;

      if (p == '主规律') {
        this.rule = '规律';
      } else if (a == '子规律') {
        this.rule = p;
        this.queryObj.pType = p;
      } else {
        this.rule = a;
        this.queryObj.pType = p;
        this.queryObj.aType = a;
      }

      this.loadData(1);
    },
    //价格选择
    priceSelect: function priceSelect(item) {
      this.finished = false;
      this.priceShow = false;
      this.price = item.name;
      this.queryObj.price = item.value;
      this.loadData(1);
    },
    //清空条件
    clear: function clear() {
      this.finished = false;
      this.operator = "运营商";
      this.address = "归属地";
      this.rule = "规律";
      this.price = "价格";
      this.queryObj = {};
      this.loadData();
    },
    // 验证身份证号码的合法性
    idCardValidator: function idCardValidator(val) {
      var r = isIdcard(val);

      if (!r) {
        return "身份证号码不合法，请填写正确的身份证号码";
      }
    },
    // 验证联系电话的合法性
    phoneValidator: function phoneValidator(val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        return "请填写正确的联系电话";
      }
    },
    // 验证姓名的合法性
    nameValidator: function nameValidator(val) {
      if (!/^[\u4e00-\u9fa5·]{2,16}$/.test(val)) {
        return "请填写正确的开户人姓名";
      }
    },
    // 检测联系号码填写是否正确
    checkPhone: function checkPhone() {
      return /^1[3456789]\d{9}$/.test(this.orderObj.userInfo.phone);
    },
    // 发送短信验证码
    sendCode: function sendCode() {
      var _this8 = this;

      if (this.checkPhone()) {
        this.sendCodeDisable = true;
        var url = "/haoma52-server/index/send-code";
        var params = {
          phoneNumber: this.phoneObj.phoneNumber,
          phone: this.orderObj.userInfo.phone
        };
        this.$axios.post(url, params).then(function () {
          _this8.startCount();
        });
      }
    },
    // 开启倒计时 60s
    startCount: function startCount() {
      if (this.seconds > 0) {
        this.sendCodeText = this.seconds-- + "后再次发送";

        var _this = this;

        setTimeout(function () {
          _this.startCount();
        }, 1000);
      } else {
        this.sendCodeDisable = false;
        this.sendCodeText = "发送验证码";
        this.seconds = 60;
      }
    },
    // 确定选中的 省 市 区县
    confirmAddress: function confirmAddress(item) {
      item = item.selectedOptions;
      this.orderObj.userInfo.province = item[0].text;
      this.orderObj.userInfo.city = item[1].text;
      this.orderObj.userInfo.area = item[2].text;
      this.areaStr = item[0].text + " " + item[1].text + " " + item[2].text;
      this.addressShow = false;
      this.showAddress = true;
    },
    //表单提交回调函数
    submitOrder: function submitOrder() {
      var _this9 = this;

      //不可重复点击
      this.submitBtnFlag = true; // 将当前请求的地址进行编码后传入到服务端

      this.orderObj.pageUrl = encodeURIComponent(window.location.href);
      this.orderObj.customerName = this.$route.params.source; // 获取URL参数

      var urlParams = new URLSearchParams(window.location.search); // 遍历参数列表

      var _iterator = _createForOfIteratorHelper(urlParams),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var param = _step.value;

          var _param = _slicedToArray(param, 2),
              key = _param[0],
              value = _param[1]; // 判断参数是否存在于指定列表中


          if (['callback', 'landingid', 'gdt_vid', 'clickid', 'clickId', 'click_id', 'qz_gdt'].includes(key)) {
            this.orderObj.clickid = value;
            break; // 找到一个有值的参数后即跳出循环
          }
        } //提交所需参数

      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var url = "/ad-server/order/submit-order-pre-wepay";
      var params = this.orderObj;
      this.$axios.post(url, params).then(function (resp) {
        var orderId = resp.data.data;
        var ua = window.navigator.userAgent.toLowerCase();

        if (ua.indexOf('micromessenger') != -1) {
          var redirectUrl = encodeURIComponent("https://m.52haoma.com/order-result/" + orderId + "?pay=1"); // 微信浏览器

          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0e48b3d222d14003&redirect_uri=" + redirectUrl + "&response_type=code&scope=snsapi_base#wechat_redirect";
        } else {
          _this9.$router.push('/order-result/' + orderId + "?pay=1");
        }
      }).finally(function () {
        //恢复点击
        _this9.submitBtnFlag = false;
      });
    },
    clearOrderObj: function clearOrderObj() {
      this.orderObj = {
        userInfo: {}
      };
    }
  }
};